import React, {useEffect, useState} from "react"
import "./ChatMessages.scss";
import Message from "../../../../../components/message/Message";
import useWebSocket from "../../../../../websocket/useWebSocket";
import {dataHeader, get} from "../../../../../api";
import {useParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const ChatMessages = ({chatUsers}) => {
    const {chatId} = useParams();
    const [userImageUrls, setUserImageUrls] = useState({});
    const [messages, setMessages] = useState([]);
    const {lastMessage, lastReaction, lastAttachment} = useWebSocket();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [isEnd, setIsEnd] = useState(false);

    const loadMessages = () => {
        get(`chats/${chatId}/messages?page=${page}`)
            .then(newMessages => {
                setMessages(prev => prev ? [...prev, ...newMessages] : newMessages);
                newMessages.length < 20 ? setIsEnd(true) : setPage(prev => prev + 1);
                return true;
            })
            .then(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        setMessages([]);
        setPage(0);

        loadMessages();
    }, [chatId]);

    useEffect(() => {
        if (lastMessage && lastMessage.chat_id === chatId) {
            const messageInMessages = messages.find(message => message.id === lastMessage.id);
            if (!messageInMessages) {
                setMessages(prev => [{...lastMessage, reactions: [], attachments: []}, ...prev]);
            } else if (lastMessage.content === "") {
                setMessages(prev => prev.filter(message => message.id !== lastMessage.id));
            } else {
                setMessages(prev => prev.map(message => message.id === lastMessage.id ? {
                    ...lastMessage,
                    reactions: message.reactions,
                    attachments: message.attachments
                } : message));
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        const foundMessage = lastReaction ? messages.find(message => message.id === lastReaction.message_id) : null;
        if (foundMessage && foundMessage.reactions) {
            if (lastReaction.code === "") {
                setMessages(prev => prev.map(message => message.id === lastReaction.message_id ? {
                    ...message,
                    reactions: message.reactions.filter(reaction => reaction.id !== lastReaction.id),
                    attachments: message.attachments
                } : message));
            } else {
                setMessages(prev => prev.map(message => message.id === lastReaction.message_id ? {
                    ...message,
                    reactions: [...message.reactions, lastReaction],
                    attachments: message.attachments
                } : message));
            }
        }
    }, [lastReaction]);

    useEffect(() => {
        const foundMessage = lastAttachment ? messages.find(message => message.id === lastAttachment.message_id) : null;
        if (foundMessage && foundMessage.attachments) {
            if (lastAttachment.document_name === "") {
                setMessages(prev => prev.map(message => message.id === lastAttachment.message_id ? {
                    ...message,
                    reactions: message.reactions,
                    attachments: message.attachments.filter(attachment => attachment.id !== lastAttachment.id)
                } : message));
            } else {
                setMessages(prev => prev.map(message => message.id === lastAttachment.message_id ? {
                    ...message,
                    reactions: message.reactions,
                    attachments: [...message.attachments, lastAttachment]
                } : message));
            }
        }
    }, [lastAttachment]);

    useEffect(() => {
        if (chatUsers.length > 0) {
            const userImageUrls = {};
            chatUsers.forEach(chatUser => {
                userImageUrls[chatUser.id] = null;
                chatUser.document_id && get(`documents/${chatUser.document_id}`, dataHeader)
                    .then(image => {
                        const urlCreator = window.URL || window.webkitURL;
                        userImageUrls[chatUser.id] = urlCreator.createObjectURL(image);
                    })
                    .catch(error => {
                        console.error("There was an error fetching the image!", error);
                    });
            });
            setUserImageUrls(userImageUrls);
        }
    }, [chatUsers]);


    return (
        <div className="chat-messages" id="scrollable">{
            <InfiniteScroll
                scrollableTarget="scrollable"
                inverse={true}
                style={{display: "flex", flexDirection: "column-reverse"}}
                dataLength={messages ? messages.length : 0}
                next={loadMessages}
                hasMore={!isEnd}
                loader={<div className="info">Loading...</div>}
                endMessage={
                    <div className="info">You've reached the end!</div>
                }
            >
                {!isLoading && chatUsers && messages.map((message, index) => (
                    <Message key={index}
                             message={message}
                             chatUsers={chatUsers}
                             user={chatUsers.find(user => user.id === message.user_id)}
                             userImageUrl={userImageUrls[message.user_id]}
                    />
                ))}
            </InfiniteScroll>
        }
        </div>
    );
};

export default ChatMessages;
