import React, {useState, useEffect} from "react";
import "./Chat.scss";
import {get} from "../../../../api";
import Image from "../../../../components/image/Image";
import ChatUsers from "./ChatUsers/ChatUsers";
import ChatInput from "../../../../components/chat-input/ChatInput";
import ChatMessages from "./ChatMessages/ChatMessages";
import {useParams} from "react-router-dom";

const Chat = () => {
    const {chatId} = useParams();

    const [chat, setChat] = useState();
    const [project, setProject] = useState();
    const [chatUsers, setChatUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (chatId) {
            get(`chats/${chatId}`)
                .then(chat => {
                    setChat(chat);

                    if (chat && chat.project_id) {
                        return get(`projects/${chat.project_id}`).then(project => {
                            setProject(project);
                            return chat;
                        });
                    } else {
                        return chat;
                    }
                })
                .then(chat => {
                    if (chat) {
                        return Promise.all([
                            get(`chat/${chatId}/users`),
                        ]);
                    } else {
                        return [null];
                    }
                })
                .then(([chatUsers]) => {

                    if (chatUsers) {
                        setChatUsers(chatUsers);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }

        return () => {
            setChat(null);
            setProject(null);
            setChatUsers([]);
            setIsLoading(true);
        };
    }, [chatId]);

    return (

        <div className="chat">
            <header className="header">
                {project && (
                    <div className="header">
                        <div className="info">
                            <Image imageId={project.document_id} name={project.name} color={project.color} radius={30}/>
                            <div className="name">
                                {chat.name}
                            </div>
                        </div>
                        <ChatUsers/>
                    </div>
                )}
            </header>
            <main>
                {!isLoading && chatUsers && <ChatMessages chatUsers={chatUsers}/>}
            </main>
            <footer className="footer">
                <ChatInput/>
            </footer>
        </div>

    );
};

export default Chat;