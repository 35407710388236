import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import {useDropzone} from "react-dropzone";
import {usePopper} from "react-popper";
import EmojiPicker from "emoji-picker-react";
import "./ChatInput.scss";
import {useParams} from "react-router-dom";
import {dataHeader, post} from "../../api";

const ChatInput = () => {
    const {userId, chatId} = useParams();

    const [files, setFiles] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);

    const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
        placement: "top-start",
    });

    const onSubmit = (values, {resetForm}) => {
        if (values.message.trim() !== "" || files.length > 0) {
            post(`chats/${chatId}/messages`, {
                "user_id": userId,
                "chat_id": chatId,
                "content": values.message,
            }).then((message) => {
                if (files.length > 0) {
                    const payloadBody = new FormData();
                    files.forEach((file) => {
                        payloadBody.append(`documents`, file);
                    });
                    post(`message/${message.id}/attachments`, payloadBody, dataHeader)
                }
                resetForm();
                setFiles([]);
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            message: "",
        },
        onSubmit: onSubmit,
    });

    useEffect(() => {
        if (showEmojiPicker && update) {
            update();
        }
    }, [showEmojiPicker, update]);

    const onDrop = (acceptedFiles) => {
        const newFiles = acceptedFiles.filter((file) =>
            !files.some((existingFile) => existingFile.name === file.name)
        );
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        noClick: true,
    });

    const removeFile = (fileName) => {
        setFiles(files.filter((file) => file.name !== fileName));
    };

    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };

    const onEmojiClick = (emojiObject) => {
        formik.setFieldValue("message", `${formik.values.message}${emojiObject.emoji}`);
        setShowEmojiPicker(false);
    };

    return (
        <form onSubmit={formik.handleSubmit} className="chat-input">
            <div {...getRootProps({className: "dropzone"})} className="chat-input-field">
                <input
                    type="text"
                    name="message"
                    placeholder="Message"
                    className="text-field"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <input {...getInputProps()} style={{display: "none"}}/>
            </div>
            <div className="chat-buttons">
                <img
                    src="/icons/emoji_icon.svg"
                    alt="Emoji"
                    className="button emoji"
                    ref={setReferenceElement}
                    onClick={toggleEmojiPicker}
                />
                {showEmojiPicker && (
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="emoji-popper">
                        <EmojiPicker onEmojiClick={onEmojiClick}/>
                    </div>
                )}
                {files.length > 0 && (
                    <div className="file-preview">
                        {files.map((file) => (
                            <div key={file.name} className="file-item">
                                {file.name}
                                <img
                                    src="/icons/remove_file_icon.svg"
                                    alt="Remove file"
                                    onClick={() => removeFile(file.name)}
                                    className="remove-button"
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div className="submits">
                    <div className="button primary">
                        <img src="/icons/call_icon.svg" alt="Call Icon"/>
                        Call
                    </div>
                    <button type="submit" className="button primary">
                        <img src="/icons/send_icon.svg" alt="Send Icon"/>
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ChatInput;