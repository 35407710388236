import React, {useCallback, useEffect, useRef, useState} from "react";
import "./Message.scss";
import {useParams} from "react-router-dom";
import {usePopper} from "react-popper";
import Modal from "react-modal";
import {useFormik} from "formik";
import {put, remove} from "../../api";
import ReactionSelector from "../reaction/reaction-selector/ReactionSelector";
import ReactionCounter from "../reaction/reaction-counter/ReactionCounter";
import Attachment from "../attachment/Attachment";
import Image from "../image/Image";

const Message = ({message, chatUsers, user, userImageUrl}) => {
    const {userId} = useParams();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "auto"
    });

    const popupRef = useRef();

    const [isEditing, setIsEditing] = useState(false);
    const editRef = useRef(null);

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    useEffect(() => {
        if (isEditing && editRef.current) {
            editRef.current.focus();
        }
    }, [isEditing]);

    const togglePopup = useCallback((event) => {
        event.stopPropagation();
        setPopupVisible(prev => !prev);
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !referenceElement.contains(event.target)) {
            setPopupVisible(false);
        }
    }, [referenceElement]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const formik = useFormik({
        initialValues: {
            editedMessage: message.content
        },
        onSubmit: (values) => {
            if (values.editedMessage === "" && message.attachments.length === 0) {
                setDeleteModalOpen(true);
            } else if (values.editedMessage !== "") {
                message.content = values.editedMessage
                put(`chats/${message.chat_id}/messages/${message.id}`, message);
            }
            values.editedMessage = message.content;
            setIsEditing(false);
        }
    });

    useEffect(() => {
        handleCancelEdit();
    }, [message]);

    const handleEditMessage = () => {
        setIsEditing(true);
        setPopupVisible(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.handleSubmit();
        } else if (event.key === "Escape") {
            setIsEditing(false);
        }
    };

    const handleCancelEdit = () => {
        formik.setFieldValue("editedMessage", message.content);
        setIsEditing(false);
    }

    const deleteMessageAndCloseModal = () => {
        remove(`chats/${message.chat_id}/messages/${message.id}`);
        setDeleteModalOpen(false);
    };

    return (
        <>
            <div className="message"
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
            >
                <ReactionSelector isVisible={isHovered}
                                  messageId={message.id}
                                  position={userId === message.user_id ? "right" : "left"}
                                  reactions={message.reactions}
                />
                <div className={`main ${userId === message.user_id ? "my-message" : "other-message"}`}
                     key={message.id}>
                    <Image imageId={user.document_id} name={`${user.name} ${user.surname}`} color={user.color}
                           radius={35} imageSource={userImageUrl}/>
                    <div>
                        <div
                            className={`message-content ${userId === message.user_id ? "my-message" : "other-message"}`}
                            onBlur={handleCancelEdit}>
                            {isEditing ? (
                                <input
                                    ref={editRef}
                                    type="text"
                                    name="editedMessage"
                                    value={formik.values.editedMessage}
                                    onChange={formik.handleChange}
                                    onKeyDown={handleKeyDown}
                                    className="edit-message-input"
                                />
                            ) : (
                                message.content
                            )}
                            {message.attachments && message.attachments.map((attachment, index) => (
                                    <Attachment key={index} attachment={attachment} isEditing={isEditing}/>
                                )
                            )}
                        </div>
                    </div>
                    {userId === message.user_id && (
                        <img
                            src="/icons/dots_icon.svg"
                            alt="Dots Icon"
                            className="message-options-button"
                            ref={setReferenceElement}
                            onClick={togglePopup}
                        />
                    )}
                    {isPopupVisible && (
                        <div
                            ref={(element) => {
                                setPopperElement(element);
                                popupRef.current = element;
                            }}
                            style={styles.popper}
                            {...attributes.popper}
                            className="popup-menu"
                        >
                            <div onClick={handleEditMessage} className="item">Edit message</div>
                            <div onClick={() => {
                                setDeleteModalOpen(true);
                                setPopupVisible(false);
                            }} className="item">Delete message
                            </div>
                        </div>
                    )}
                </div>
                <ReactionCounter position={userId === message.user_id ? "right" : "left"}
                                 messageId={message.id}
                                 chatUsers={chatUsers}
                                 reactions={message.reactions}/>
            </div>
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setDeleteModalOpen(false)}
                className="modal"
                overlayClassName="overlay"
                contentLabel="Delete Message Confirmation"
            >
                <div className="modal-content">
                    <p>Are you sure you want to delete this message?</p>
                    <div>
                        <button onClick={deleteMessageAndCloseModal} className="button primary">Yes</button>
                        <button onClick={() => setDeleteModalOpen(false)} className="button secondary">No</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Message;
