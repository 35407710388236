import React, {useEffect, useState} from "react";
import "./ReactionCounter.scss";
import {useParams} from "react-router-dom";
import {usePopper} from "react-popper";
import {handleAddOrRemoveReaction} from "../reactionCommons";
import classNames from "classnames";

const ReactionCounter = ({position, messageId, chatUsers, reactions}) => {
    const {chatId, userId} = useParams();
    const [visibleUsersReacted, setVisibleUsersReacted] = useState(false);
    const [usersReacted, setUsersReacted] = useState("");
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom",
        strategy: "fixed",
    });
    const reactionCounts = reactions.reduce((acc, reaction) => {
        acc[reaction.code] = (acc[reaction.code] || 0) + 1;
        return acc;
    }, {});

    const handleMouseEnter = (emojiCode, ref) => {
        const filteredUsers = reactions
            .filter(reaction => reaction.code === emojiCode)
            .map(reaction => chatUsers.find(user => user.id === reaction.user_id));

        let names = filteredUsers
            .map(user => user.name)
            .slice(0, 3)
            .join(", ");

        if (filteredUsers.length > 3) {
            names += " and more";
        }

        names += " reacted with " + emojiCode.split(" ").map(code => String.fromCodePoint(parseInt(code, 16))).join("");

        setUsersReacted(names);
        setReferenceElement(ref);
        setVisibleUsersReacted(true);
    };

    const handleMouseLeave = () => {
        setVisibleUsersReacted(false);
    };


    return (
        <div className={`reaction-counter ${position}`}>
            {Object.entries(reactionCounts).map(([emojiCode, count]) => (
                <button key={emojiCode}
                        ref={setReferenceElement}
                        className={classNames("emoji-button", {
                            "is-selected": reactions.filter(reaction => reaction.user_id === userId).some(reaction => reaction.code === emojiCode)
                        })}
                        onMouseEnter={(e) => handleMouseEnter(emojiCode, e.currentTarget)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => {
                            setVisibleUsersReacted(false)
                            handleAddOrRemoveReaction(reactions, emojiCode, chatId, userId, messageId)
                        }}>
                    {emojiCode.split(" ").map(code => String.fromCodePoint(parseInt(code, 16))).join("")}
                    <span className="count">{count}</span>
                </button>
            ))}
            {visibleUsersReacted && (
                <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="users">
                    {usersReacted}
                </div>
            )}
        </div>
    );
};

export default ReactionCounter;