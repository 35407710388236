import React, {useEffect, useState} from "react";
import Image from "../../../../../components/image/Image";
import "./ChatUsers.scss";
import {useParams} from "react-router-dom";
import {get} from "../../../../../api";

const ChatUsers = () => {
    const {chatId} = useParams();
    const [activeUsers, setActiveUsers] = useState([]);

    useEffect(() => {
        get(`chat/${chatId}/users/active`)
            .then(users => {
                setActiveUsers(users);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [chatId]);

    return (
        <>
            {
                activeUsers &&
                <div className="chat-users">
                    <div className="images">
                        {activeUsers.slice(0, Math.min(3, activeUsers.length)).map(user => (
                            <div key={user.id} className="img">
                                <Image imageId={user.document_id} name={`${user.name} ${user.surname}`}
                                       color={user.color}
                                       radius={25}/>
                            </div>
                        ))}
                    </div>
                    <div>
                        {`${activeUsers.length}`}
                    </div>
                </div>
            }
        </>
    );
}

export default ChatUsers;